import React from 'react';
import Main from './Main.js'

class App extends React.Component{
  render(){      
    return (
      <Main></Main>
    )
  }
}

export default App;
