import firebase from 'firebase'
const config = {
    apiKey: "AIzaSyAA4JA4UBLe3Zn2mzD6ppQWGYXnvsrVZQs",
    authDomain: "octavo-501f9.firebaseapp.com",
    databaseURL: "https://octavo-501f9.firebaseio.com",
    projectId: "octavo-501f9",
    storageBucket: "",
    messagingSenderId: "438954623086",
    appId: "1:438954623086:web:5b8acc91bf22c0efc7267f"
};
firebase.initializeApp(config);
export default firebase;